<template>
  <div class="mt-6" style="height:90vh;">
    
      <p class="panel-heading is-header">Gallery Records</p>

      <div class="is-flex is-flex-direction-row is-justify-content-center" style="height:90%">
        <div class="column is-two-thirds-desktop is-centered"
        style="height:100%; overflow-y:scroll"
        ref="scrollContent"
        >
          <!-- Letters first -->
          <div v-for="letter in letters" :key="letter">
            <p
              :ref="letter"
              v-if="HasAnyRecords(letter)"
              class="panel-block is-dark-blue has-text-white"
            >
              {{ letter }}
            </p>
            <!-- Then records -->
            <div class="" v-for="record in records" :key="record.id">
              <div v-if="StartsWithLetter(letter, record.title)">
                <div class="panel-block">
                  <a
                    class="is-link is-outlined is-text has-text-left"
                    @click="selectRecord(record)"
                  >
                    {{loggedIn ? '('+record.object_id+')' : ''}} {{ record.title }}
                  </a>
                  <a
                    class="button is-small is-rounded ml-2"
                    :class="{'is-primary': showEditForm && selectedRecord.id == record.id}"
                    @click="editRecord(record)"
                    v-if="loggedIn"
                    >{{editBtnText(record.id)}}</a
                  >
                </div>

                <div
                  class=""
                  v-if="showEditForm && selectedRecord.id == record.id"
                >
                  <AddRecordForm
                    :isEditForm="true"
                    :location_id="selectedRecord.location_id"
                    :recordToEdit="selectedRecord"
                    @close="showEditForm = false"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Alphabet filter buttons -->
        <div class="is-flex is-flex-direction-column is-justify-content-left mt-2 mr-2" style="max-width: 20px">
          <div v-for="letter in letters" :key="letter" class="is-size-7-mobile">
            <a @click="GoToLetter(letter)">{{ letter }}</a>
          </div>
        </div>
      </div>
    
  </div>
</template>

<script>
import AddRecordForm from "./AddRecordForm.vue";
export default {
  components: { AddRecordForm },
  data() {
    return {
      showEditForm: false,
      selectedRecord: {},
      records: [],
      articles:["the","a","an"]
    };
  },
  async mounted() {
    this.$store.commit("AWAIT_RESPONSE", true);
    this.records = await this.$store.dispatch("records/getAllBulk");
    this.SortRecords();
    this.$store.commit("AWAIT_RESPONSE", false);
  },
  computed: {
    letters() {
      let letters = [];
      for (let i = "A".charCodeAt(0); i <= "Z".charCodeAt(0); i++) {
        letters.push(String.fromCharCode([i]));
      }
      return letters;
    },
    loggedIn() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    editBtnText(recId){
      if(this.showEditForm && this.selectedRecord.id == recId) return "Close";
      else return "Edit";
    },
    async selectRecord(record) {
      this.selectedRecord = record;
      var loc = await this.$store.dispatch(
        "locations/getLocationFromId",
        record.location_id
      );

      this.$router.push({
        name: 'Records',
        params:{
          id: loc.collection_id,
          location_id: record.location_id,
          record_id: record.id
        }
      });
    },
    editRecord(record) {
      if(this.selectedRecord == record ){
        this.showEditForm = !this.showEditForm;
      }
      else{
        this.selectedRecord = record;
        this.showEditForm = true;
      }
    },
    StartsWithLetter(letter, title) {
      if(this.StartsWithArticle(title)) title = title.substring(title.indexOf(" ") + 1);
      return title.charAt(0).toUpperCase() == letter;
    },
    HasAnyRecords(letter) {
      if (this.records.length <= 0) return false;
      return this.records.some((c) => {
        var title = c.title;
        if(this.StartsWithArticle(title)) title = title.substring(title.indexOf(" ") + 1);
        return title.charAt(0).toUpperCase() == letter;
      });
    },
    SortRecords() {
      if (this.records.length > 0){
        this.records.sort((a, b) => {
          var titleA = a.title;
          var titleB = b.title;
          if(this.StartsWithArticle(titleA)) titleA = titleA.substring(titleA.indexOf(" ") + 1);
          if(this.StartsWithArticle(titleB)) titleB = titleB.substring(titleB.indexOf(" ") + 1);
          (titleA > titleB ? 1 : -1)
        });
      }
    },
    GoToLetter(letter){
      if(this.$refs[letter]){
        var scrollY = this.$refs.scrollContent.offsetTop;
        var y = this.$refs[letter].offsetTop;

        this.$nextTick(()=>{
          this.$refs.scrollContent.scrollTop = y - scrollY;
        });
        
      }
    },
    StartsWithArticle(string){
      return this.articles.some(x => x == string.split(" ")[0].toLowerCase())
    }
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
    width: 0px;
}
</style>